import React, { useEffect, useState } from "react";

const TimerComponent = ({ item, testIsRun, setTestIsRun, currTime }) => {
  const [remainingTime, setRemainingTime] = useState("");
  const [curTime, setCurTime] = useState(new Date(currTime).getTime());

  useEffect(() => {
    // Initialize curTime on mount
    setCurTime(new Date(currTime).getTime());
  }, [currTime]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurTime((prev) => prev + 1000);
      const exactDate = new Date(item?.publish_at).getTime();
      const difference = exactDate - curTime;

      if (difference <= 0) {
        setRemainingTime("00:00:00:00");
        setTestIsRun(true);
        clearInterval(interval); // Stop interval when the test starts
        return;
      }

      const days = Math.floor(difference / (24 * 60 * 60 * 1000));
      const hours = Math.floor(
        (difference % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)
      );
      const minutes = Math.floor((difference % (60 * 60 * 1000)) / (60 * 1000));
      const seconds = Math.floor((difference % (60 * 1000)) / 1000);

      setRemainingTime(
        `${days.toString().padStart(2, "0")}:${hours
          .toString()
          .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
          .toString()
          .padStart(2, "0")}`
      );
    }, 1000);

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [item, curTime, setTestIsRun]);

  return (
    <div>
      {!testIsRun && <p className="timing">Test Starting in {remainingTime}</p>}
      {testIsRun && <p className="timing">Test in Progress</p>}
    </div>
  );
};

export default TimerComponent;
