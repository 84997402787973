import React, { useEffect, useState } from "react";
import PageNavBar from "../../components/NavBar/PageNavBar";
import MiddleNavBar from "../../components/NavBar/MiddleNavBar";
import "./Myexam.scss";
import TestCard from "../../components/TestCard/TestCard";
import axios from "axios";
import { useSelector } from "react-redux";
import InsideLoader from "../../components/InsideLoader/InsideLoader";
import { api, api2 } from "../..";
import { toast } from "react-toastify";

const MyExams = () => {
  const { user } = useSelector((state) => state?.user);
  const { drawerValue } = useSelector((state) => state?.customDrawer);
  const [tabs, setTabs] = useState("upcoming");
  const [loading, setLoading] = useState(false);
  const [testData, setTestData] = useState([]);

  const switcherFun = (data) => {
    setTabs(data);
    fetchExamData(data);
  };

  useEffect(() => {
    fetchExamData("upcoming");
  }, []);

  const fetchExamData = async (fetchData) => {
    try {
      setLoading(true);

      let auth_token = localStorage.getItem("auth_token");

      if (!auth_token) {
        toast.error("Please Login Again");
        return;
      }

      const headers = {
        Authorization: `Bearer ${auth_token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      };
      const { data } = await api2.get(`fetch/${fetchData}/test`, { headers });
      if (data && data.success) {
        toast.success(data.message);
        setTestData(data?.test);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setTestData([])
    }
  };
  return (
    <>
      <PageNavBar name="My Exams" />
      <MiddleNavBar />
      <div className="my-exam-cont">
        <div className="my-exam-switch-cont ">
          <div onClick={() => switcherFun("upcoming")}>
            <p className={tabs === "upcoming" ? "active" : ""}>Upcoming</p>
            <div className={`bar ${tabs === "upcoming" ? "baractive" : ""}`} />
          </div>
          <hr />
          <div onClick={() => switcherFun("ongoing")}>
            <p className={tabs === "ongoing" ? "active" : ""}>Ongoing</p>
            <div className={`bar ${tabs === "ongoing" ? "baractive" : ""}`} />
          </div>
          <hr />
          <div onClick={() => switcherFun("completed")}>
            <p className={tabs === "completed" ? "active" : ""}>Completed</p>
            <div className={`bar ${tabs === "completed" ? "baractive" : ""}`} />
          </div>
          <hr />
        </div>

        {tabs === "upcoming" ? (
          <div className="test-cont">
            {loading && <InsideLoader />}
            {!loading && testData && testData.length > 0 ? (
              testData && testData?.map((item, index) => (
                <TestCard id={item?.test_id} item={item} key={index} />
                
              ))
            ) : (
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "700",
                  color: "red",
                  marginTop: "30px",
                }}
              >
                No upcoming exams found.
              </p>
            )}
          </div>
        ) : (
          ""
        )}
        {tabs === "ongoing" ? (
          <div className="test-cont">
            {loading && <InsideLoader />}
            {!loading && testData && testData.length > 0 ? (
              testData.map((item, index) => (
                <TestCard id={item?.test_id} item={item} key={index} />
              ))
            ) : (
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "700",
                  color: "red",
                  marginTop: "30px",
                }}
              >
                No ongoing exams found.
              </p>
            )}
          </div>
        ) : (
          ""
        )}
        {tabs === "completed" ? (
          <div className="test-cont">
            {loading && <InsideLoader />}
            {!loading && testData && testData.length > 0 ? (
              testData.map((item, index) => (
                <TestCard id={item?.test_id} item={item} key={index} />
              ))
            ) : (
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "700",
                  color: "red",
                  marginTop: "30px",
                }}
              >
                No completed exams found.
              </p>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default MyExams;
