import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api2 } from "..";

const initialState = {
  user: {},
};

//register user
export const registerUser = createAsyncThunk(
  "user/registerUser", // Changed action type here
  async ({
    mobile_number,
    name,
    email,
    schoolname,
    dob,
    gender,
    country,
    state,
    city,
    referCode,
    deviceId,
  }) => {
    try {
      
      const {data} = await api2.post(`/auth/register`, {
        mobile_number,
        name,
        email,
        schoolname,
        dob,
        gender,
        country,
        state,
        city,
        referCode,
        deviceId,
      })

      if (data && data.success) {
        return data;
      }
    } catch (error) {
      console.log(error)
      return error
    }
    // return data;
  }
);

//login user
export const loginUser = createAsyncThunk(
  "user/loginUser", // Changed action type here
  async ({ mobileNo, deviceId }) => {
    try {
      const fcmToken = localStorage.getItem("fcmToken");
      const { data } = await api2.post(`/auth/login`, {
        mobile_number: mobileNo,
        device_id: deviceId,
        fcm_id: fcmToken,
      });

      if (data && data.success) {
        return data;
      }
    } catch (error) {
      console.log(error);
      return;
    }
  }
);

//logout user
export const logoutUser = createAsyncThunk("user/logoutUser", async () => {
  return;
});

//verify OTP
export const verifyOtp = createAsyncThunk(
  "user/verifyOtp",
  async ({ enterOtp, mobileNo }) => {
    try {
      const { data } = await api2.post(`/auth/verification-otp`, {
        mobile_number: mobileNo,
        otp: enterOtp,
      });

      if (data && data.success) {
        return data;
      }
    } catch (error) {
      console.log(error);
      return error;
    }
  }
);

//fetching user
export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async ({ auth_token }) => {
    try {
      if (!auth_token) {
        console.log("no token");
        return;
      }
      const headers = {
        Authorization: `Bearer ${auth_token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      };

      const { data } = await api2.get(`/user/profile`, { headers });

      if (data && data.success) {
        return data;
      }
      
    } catch (error) {
      return error;
    }
  }
);

//Slice definition for user state
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      localStorage.setItem("user", JSON.stringify(state.user));
    },
  },
  extraReducers: (builder) => {
    builder

      //login user
      .addCase(loginUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      //register user
      .addCase(registerUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload.user;
        localStorage.setItem("auth_token", action.payload.user.auth_token)
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      //logout user
      .addCase(logoutUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.status = "succeeded";
        state.user = initialState;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      //fetch user
      .addCase(fetchUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload.user;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      // OTP verification cases
      .addCase(verifyOtp.pending, (state) => {
        state.otpStatus = "loading";
      })
      .addCase(verifyOtp.fulfilled, (state) => {
        state.otpStatus = "succeeded";
        state.user = { ...state.user, isVerified: true }; // Mark user as verified
        state.user.is_register &&
          localStorage.setItem("user", JSON.stringify(state.user));
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.otpStatus = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
