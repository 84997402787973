import React, { useEffect, useState } from "react";
import loginImg from "../../../assets/login-screen-banner.jpg";
import "./login.scss";
import { GrLinkNext } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser, loginUser, verifyOtp } from "../../../slices/userSlice";
import Loader from "../../../components/Loader/Loader";
import { toast } from "react-toastify";
import PopUpNoti from "../../../components/popup/PopUpNoti";
const Login = () => {
  const [sentOtp, setSentOtp] = useState(false);
  const [otpNoti, setOtpNoti] = useState(false);
  const [mobileNo, setMobileNo] = useState("");
  const [loading, setLoading] = useState(false);
  const [enterOtp, setEnterOtp] = useState("");
  const [registered, setRegistered] = useState(false);
  const deviceId = uuidv4(); //deviceId for tracking device login

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   fetchUser();
  // }, []);

  // //fetch user
  // const fetchUser = async () => {
  //   const fetchedUser = JSON.parse(localStorage.getItem("user"));

  //   if (fetchedUser?.data) {
  //     navigate("/");
  //   } else {
  //     navigate("/login");
  //   }
  // };

  //sent otp fuction
  const sentOtpFun = async () => {
    try {
      setLoading(true);
      if (!mobileNo) {
        toast.warning("Please enter mobile number");
        setLoading(false);
        return;
      }
      if (mobileNo.length < 10) {
        toast.warning("Please enter a valid mobile number");
        setLoading(false);
        return;
      }
      const response = await dispatch(
        loginUser({ mobileNo, deviceId })
      ).unwrap();

      if (response) {
        toast.success(response?.message);
        setRegistered(response?.isRegister);
      }
      setOtpNoti(true);
      setTimeout(() => {
        setOtpNoti(false);
      }, 3000);
      setSentOtp(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  //check otp function
  const checkOtpFuc = async () => {
    if (!enterOtp) {
      toast.warning("Please enter OTP");
      return;
    }

    setLoading(true);

    const { payload } = await dispatch(verifyOtp({ enterOtp, mobileNo }));

    if (payload && payload.success) {
      toast.success("verification successful");

      if (registered) {
        localStorage.setItem("auth_token", payload.auth_token);
        dispatch(fetchUser({ auth_token: payload.auth_token }));
        navigate("/");
      } else {
        navigate("/auth-personaldetails", {
          state: { mobileNumber: mobileNo },
        });
      }
      setLoading(false);
      return;
    }

    setLoading(false);
    toast.error("verification failed");
    return;
  };

  return (
    <div className="container">
      {loading ? (
        <Loader />
      ) : (
        <div className="logincard">
          <div className="login-banner">
            <img src={loginImg} alt="loginImg" />
            <div className="over-lap">
              <p>Welcome to</p>
              <h1>B competition</h1>
              <p>Signup to continue</p>
            </div>
          </div>
          {otpNoti && <PopUpNoti />}
          {sentOtp ? (
            <div className="login-info">
              <p className="otp-txt">Enter your verification code</p>
              <p className="otp-txt">
                we have just sent you on your mobile number
              </p>
              <input
                type="tel"
                className="inp-otp"
                placeholder="0 0 0 0"
                maxLength={4}
                value={enterOtp}
                onChange={(e) => setEnterOtp(e.target.value)}
              />
              <p className="chng-phn" onClick={() => setSentOtp(false)}>
                Change phone no?
              </p>
              <div className="btn" onClick={checkOtpFuc}>
                <GrLinkNext color="#fff" size={25} />
              </div>
            </div>
          ) : (
            <div className="login-info">
              <p className="normal-txt">Login</p>

              <div>
                <span>+91</span>
                <input
                  type="tel"
                  placeholder="Phone no"
                  maxLength={10}
                  minLength={10}
                  value={mobileNo}
                  onChange={(e) => setMobileNo(e.target.value)}
                />
              </div>

              <div className="btn" onClick={sentOtpFun}>
                <GrLinkNext color="#fff" size={25} />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Login;
