import React from "react";
import { FaMedal } from "react-icons/fa";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { IoMdClock } from "react-icons/io";
import { CiUser } from "react-icons/ci";
import { TbCoinRupee } from "react-icons/tb";
import "./testcard.scss";
import { useNavigate } from "react-router-dom";


const TestCard = ({ item }) => {
  const navigate = useNavigate();

  return (
    <div
      className="test-card"
      onClick={() =>
        navigate(`/test-detail/${item._id}`, { state: { item: item } })
      }
    >
      <div>
        <FaMedal />
      </div>
      <div>
        <div>
          <div>
            <p>{item?.testTitle}</p>
            <p className="time">Exam Date: {item?.publish_at?.split("T")[0]}</p>
          </div>
          {item?.isEnrolled && (
            <div>
              <p className="purchased">Purchased</p>
            </div>
          )}
        </div>
        <hr />
        <div>
          <div>
            <div>
              <MdOutlineCalendarMonth />
              <p>Date</p>
            </div>
            <div>{item?.publish_at?.split("T")[0]}</div>
          </div>
          <div>
            <div>
              <IoMdClock />
              <p>Time</p>
            </div>
            <div>{item?.publish_at?.split("T")[1]}</div>
          </div>
          <div>
            <div>
              <CiUser />
              <p>Students</p>
            </div>
            <div>{item?.enrolledStudents?.length}</div>
          </div>
          <div>
            <div>
              <TbCoinRupee />
              <p>Prizepool</p>
            </div>
            <div>₹ {item?.prizePool}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestCard;
