import React, { useEffect, useState } from "react";
import PageNavBar from "../../components/NavBar/PageNavBar";
import "./Test.scss";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import RightSideBar from "../../components/NavBar/RightSideBar";
import TestTimer from "../../components/TimerComponet/TestTimer";
import { toast } from "react-toastify";
import { questionSlice, testQuestionSubmit } from "../../slices/testQuestions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { api2 } from "../..";

const Test = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();

  const testQuestions = useSelector((state) => state.testQuestions);
  const { user } = useSelector((state) => state.user);

  const [currQuesIndex, setCurrQuesIndex] = useState(0);
  const [testQues, setTestQues] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [submitDialog, setSubmitDialog] = useState(false);
  const [selectOption, setSelectOption] = useState("");
  const { item, timestamp } = location.state || {};
  const [alreadyTouched, setAlreadyTouched] = useState({});
  const [loading, setLoading] = useState(false);
  const [attemptedAnswers, setAttemptedAnswers] = useState({});

  useEffect(() => {
    if (testQuestions?.selectedQuesBySubject.length === 0) {
      navigate("/");
    }
  }, [navigate, testQuestions?.selectedQuesBySubject]);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const saveHandler = () => {
    if (!selectOption) {
      toast.warn("Please select an option");
      return;
    }

    if (currQuesIndex < testQues.length - 1) {
      setCurrQuesIndex(currQuesIndex + 1);
    } else {
      toast.success("Test completed");
    }
    if (alreadyTouched[testQues[currQuesIndex]?._id]?.status === "save") {
      toast.error("You have already saved this question");
      return;
    }

    setAttemptedAnswers((prev) => ({
      ...prev,
      [testQues[currQuesIndex]?._id]: selectOption,
    }));

    dispatch(
      questionSlice.actions.setAttemptedQuestion({
        quesId: testQues[currQuesIndex]?._id,
        status: "save",
        ans: selectOption,
      })
    );

    setAlreadyTouched((prev) => ({
      ...prev,
      [testQues[currQuesIndex]?._id]: { status: "save" },
    }));

    if (currQuesIndex < testQues.length - 1) {
      setCurrQuesIndex(currQuesIndex + 1);
      setSelectOption("");
    } else {
      toast.success("Test completed");
    }
  };

  const markHandler = () => {
    if (alreadyTouched[testQues[currQuesIndex]?._id]?.status === "save") {
      if (currQuesIndex < testQues.length - 1) {
        setCurrQuesIndex(currQuesIndex + 1);
        setSelectOption("");
      } else {
        toast.success("Test completed");
      }
      return;
    }

    setAttemptedAnswers((prev) => ({
      ...prev,
      [testQues[currQuesIndex]?._id]: selectOption,
    }));

    dispatch(
      questionSlice.actions.setAttemptedQuestion({
        quesId: testQues[currQuesIndex]?._id,
        status: "marked",
        ans: selectOption,
      })
    );

    if (currQuesIndex < testQues.length - 1) {
      setCurrQuesIndex(currQuesIndex + 1);
      setSelectOption("");
    } else {
      toast.success("Test completed");
    }
  };

  const directQuesNav = (index) => {
    setCurrQuesIndex(index);
    const selectedAnswer = attemptedAnswers[testQues[index]?._id];
    setSelectOption(selectedAnswer || "");
  };

  const submitHandler = async () => {
    let submission = [];

    testQuestions.attemptedQuestion.forEach((ques) => {
      if (ques.status === "save") {
        submission.push({
          question: ques?.quesId,
          ans: ques?.ans,
        });
      }
    });

    await dispatch(
      testQuestionSubmit({
        auth_token: user?.auth_token,
        testId: item?._id,
        submission: submission,
        completedTime: item?.publish_at,
      })
    );

    navigate("/");
    window.location.reload();
  };

  const backNav = () => {
    setSubmitDialog(true);
  };

  const setOption = (ans) => {
    if (alreadyTouched[testQues[currQuesIndex]?._id]?.status === "save") {
      toast.error("You have already saved this question");
      return;
    }
    setSelectOption(ans);
  };

  useEffect(() => {
    fetchQuestionBySubject();
  }, []);

  const fetchQuestionBySubject = async () => {
    try {
      setLoading(true);
      let auth_token = localStorage.getItem("auth_token");

      if (!auth_token) {
        toast.error("Please Login Again");
        return;
      }

      const subjects = testQuestions?.selectedSubjects?.map((data) => data._id);

      const headers = {
        Authorization: `Bearer ${auth_token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      };

      const { data } = await api2.post(
        `fetch/test-question/${id}`,
        { subjectId: subjects },
        { headers }
      );

      if (data && data.success) {
        setCurrQuesIndex(0);
        setTestQues(data?.questions);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      {testQuestions?.status === "loading" && <Loader />}
      {loading && <Loader />}
      <PageNavBar
        name={item?.testTitle}
        date={item?.publish_at?.split("T")[0]}
        rightIcon="GiHamburgerMenu"
        toggleDrawer={toggleDrawer}
        backNav={backNav}
      />
      <RightSideBar
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        toggleDrawer={toggleDrawer}
        directQuesNav={directQuesNav}
        setSubmitDialog={setSubmitDialog}
        question={testQues}
        attemptedQuestions={testQuestions?.attemptedQuestion}
      />
      <TestTimer
        testEndTime={item?.unpublish_at}
        submitHandler={submitHandler}
        currentTime={timestamp}
      />
      {submitDialog && (
        <div className="submit-pop-up">
          <div>
            <div className="heading">
              <p>Are you sure you want to submit the test?</p>
            </div>
            <div className="btns">
              <button onClick={submitHandler}>Yes</button>
              <button onClick={() => setSubmitDialog(false)}>No</button>
            </div>
          </div>
        </div>
      )}
      <div className="question-switcher">
        {testQuestions?.selectedSubjects?.map((item, index) => (
          <div key={index}>{item?.subject}</div>
        ))}
      </div>
      <div className="test-rule-info">
        <p className="green">
          + {item?.pointSystem?.toString()?.split(" ")[1]}
        </p>
        <p className="red">{item?.pointSystem?.toString()?.split(" ")[4]}</p>
        <p className="rule-txt">Single choice is correct</p>
      </div>
      <div className="text-question">
        {currQuesIndex + 1}. {testQues[currQuesIndex]?.question}
      </div>
      <div className="question-options">
        {testQues &&
          testQues[currQuesIndex]?.options?.map((item, index) => {
            const isSelected =
              selectOption === item._id ||
              attemptedAnswers[testQues[currQuesIndex]?._id] === item._id;

            return (
              <div
                key={index}
                onClick={() => setOption(item?._id)} // When clicking, select an option
                className={isSelected ? "active" : ""}
                style={{
                  pointerEvents:
                    alreadyTouched[testQues[currQuesIndex]?._id]?.status ===
                    "save"
                      ? "none" // Disable clicking if the question is saved
                      : "auto",
                }}
              >
                {item.option}
              </div>
            );
          })}
      </div>
      <div className="questions-button">
        <button onClick={markHandler}>Mark & Next</button>
        <button
          onClick={() => {
            const currentQuestionStatus =
              alreadyTouched[testQues[currQuesIndex]?._id]?.status;

            if (currentQuestionStatus === "save") {
              toast.error("You have already saved this question");
              return;
            }

            setSelectOption("");
            toast.info("Selection cleared");
          }}
        >
          Clear
        </button>
        <button className="styled-btn" onClick={saveHandler}>
          Save & Next
        </button>
      </div>
    </>
  );
};

export default Test;
