import React, { useEffect, useState } from "react";

const TestTimer = ({ testEndTime, submitHandler, currentTime }) => {
  const [remainingTime, setRemainingTime] = useState("00:00:00:00");
  const [testIsRunning, setTestIsRunning] = useState(true);
  const [serverTime, setServerTime] = useState(new Date(currentTime).getTime()); // Initialize with server time

  useEffect(() => {
    const updateRemainingTime = () => {
      const endDate = new Date(testEndTime).getTime();
      const difference = endDate - serverTime;

      if (difference <= 0) {
        setRemainingTime("00:00:00:00");
        setTestIsRunning(false);
        submitHandler();
        return;
      }

      const days = Math.floor(difference / (24 * 60 * 60 * 1000));
      const hours = Math.floor(
        (difference % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)
      );
      const minutes = Math.floor((difference % (60 * 60 * 1000)) / (60 * 1000));
      const seconds = Math.floor((difference % (60 * 1000)) / 1000);

      setRemainingTime(
        `${days.toString().padStart(2, "0")}:${hours
          .toString()
          .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
          .toString()
          .padStart(2, "0")}`
      );

      setServerTime((prev) => prev + 1000); // Increment by exactly 1 second
    };

    if (testIsRunning) {
      const interval = setInterval(updateRemainingTime, 1000); // Runs every 1 second
      return () => clearInterval(interval); // Cleanup to avoid multiple intervals
    }
  }, [testIsRunning, serverTime, testEndTime, submitHandler]);

  return <div className="timer">{remainingTime}</div>;
};

export default TestTimer;
