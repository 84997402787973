import React, { useEffect, useState } from "react";
import "./PersonalDetails.scss";
import AppLogo from "../../../assets/app_logo.jpg";
import { toast } from "react-toastify";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../../slices/userSlice";
import Loader from "../../../components/Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { api, api2 } from "../../..";
const PersonalDetails = () => {
  //react hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const deviceId = uuidv4();
  const { mobileNumber } = location.state || {};

  //user states
  const [name, setName] = useState("");
  const [phoneNo, setPhoneNo] = useState(mobileNumber);
  const [email, setEmail] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [referCode, setReferCode] = useState("");
  const [term, setTerm] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({
    id: "",
    country: "",
  });
  const [selectedState, setSelectedState] = useState({
    id: "",
    state: "",
  });
  const [selectedCity, setSelectedCity] = useState({
    id: "",
    city: "",
  });
  const [inputType, setInputType] = useState("text");

  useEffect(() => {
    // Fetch all countries when the component loads
    fetchCountriesData();
  }, []);

  const fetchCountriesData = async () => {
    const { data } = await api2.get(`/fetch/country`);

    if (data) {
      setCountries(data && data?.country);
    }
  };

  //country handler
  const handleCountryChange = async (event) => {
    const selectedCountryData = JSON.parse(event.target.value);
    setSelectedCountry(selectedCountryData);

    try {
      const { data } = await api2.get(`/fetch/state/${selectedCountryData.id}`);

      if (data) {
        setStates(data?.state);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //state handler
  const handleStateChange = async (event) => {
    const selectedStateData = JSON.parse(event.target.value);
    setSelectedState(selectedStateData);

    try {
      const { data } = await api2.get(`/fetch/city/${selectedStateData.id}`);
      if (data) {
        setCities(data?.city);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //city handler
  const handleCityChange = async (event) => {
    const selectedCityData = JSON.parse(event.target.value);
    setSelectedCity(selectedCityData);
  };

  const registerHandler = async () => {
    if (
      !phoneNo ||
      !name ||
      !email ||
      !schoolName ||
      !dob ||
      !gender ||
      !selectedCountry ||
      !selectedState ||
      !selectedCity
    ) {
      toast.warning("Please provide all details");
      return;
    }

    if (!term) {
      toast.warning("Please accept the terms and conditions");
      return;
    }

    try {
      setLoading(true);
      const response = await dispatch(
        registerUser({
          mobile_number: phoneNo,
          name: name,
          email: email,
          schoolname: schoolName,
          dob: dob,
          gender: gender,
          country: selectedCountry,
          state: selectedState,
          city: selectedCity,
          referCode: referCode,
          deviceId: deviceId,
        })
      ).unwrap();

      console.log(response)
      if (response.success) {
        navigate("/");
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  return (
    <div className="container">
      {loading ? (
        <Loader />
      ) : (
        <div className="logincard">
          <div className="applogo">
            <img src={AppLogo} alt="AppLogo" />
          </div>
          <div className="basic-info">
            <p className="title">Basic Info</p>
            <div className="details">
              <div className="input-cont">
                <label>Name: <span style={{color: 'red', fontSize: '20px'}}>*</span> </label>
                <input
                  type="text"
                  placeholder="Please enter your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="input-cont">
                <label>Email:<span style={{color: 'red', fontSize: '20px'}}>*</span></label>
                <input
                  type="email"
                  placeholder="Please enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="input-cont">
                <label>School/College Name:<span style={{color: 'red', fontSize: '20px'}}>*</span></label>
                <input
                  type="text"
                  placeholder="Please enter your school/college name"
                  value={schoolName}
                  onChange={(e) => setSchoolName(e.target.value)}
                />
              </div>

              <div className="input-cont">
                <label>Date of Birth:<span style={{color: 'red', fontSize: '20px'}}>*</span></label>
                <input
                  type={inputType}
                  placeholder="dd-mm-yy"
                  onFocus={() => setInputType("date")}
                  onBlur={() => setInputType("text")}
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                />
              </div>
              <div className="gender-cont ">
                <p>Please select your gender<span style={{color: 'red', fontSize: '20px'}}>*</span></p>
                <input
                  type="radio"
                  name="gender"
                  id="male"
                  value="Male"
                  onChange={(e) => setGender(e.target.value)}
                />
                <label htmlFor="male">Male</label>
                <input
                  type="radio"
                  name="gender"
                  id="female"
                  value="Female"
                  onChange={(e) => setGender(e.target.value)}
                />
                <label htmlFor="female">female</label>
              </div>
            </div>
          </div>

          <div className="basic-info">
            <div className="details">
              <div className="input-cont">
                <label>Select State:<span style={{color: 'red', fontSize: '20px'}}>*</span></label>
                <select
                  value={JSON.stringify(selectedCountry.country)}
                  onChange={(e) => handleCountryChange(e)}
                >
                  <option value="">Select your State:</option>
                  {countries &&
                    countries.map((country, index) => (
                      <option
                        key={index}
                        value={JSON.stringify({
                          id: country._id,
                          country_name: country.country,
                        })}
                      >
                        {country.country}
                      </option>
                    ))}
                </select>
              </div>

              <div className="input-cont">
                <label> Select District:<span style={{color: 'red', fontSize: '20px'}}>*</span> </label> 
                <select
                  value={JSON.stringify(selectedState.state)}
                  onChange={(e) => handleStateChange(e)}
                >
                  <option value="">Select your District</option>
                  {states &&
                    states.map((state, index) => (
                      <option
                        key={index}
                        value={JSON.stringify({
                          id: state._id,
                          country_name: state.state,
                        })}
                      >
                        {state.state}
                      </option>
                    ))}
                </select>
              </div>

              <div className="input-cont">
                <label>Select City: <span style={{color: 'red', fontSize: '20px'}}>*</span></label>
                <select
                  value={JSON.stringify(selectedCity.city)}
                  onChange={(e) => handleCityChange(e)}
                >
                  <option value="">Select your City: </option>
                  {cities &&
                    cities.map((city, index) => (
                      <option
                        key={index}
                        value={JSON.stringify({
                          id: city._id,
                          country_name: city.city,
                        })}
                      >
                        {city.city}
                      </option>
                    ))}
                </select>
              </div>

              {/* <div className="input-cont">
                <label>
                  Refer Code <span style={{ color: "red" }}>(optional)</span>
                </label>
                <input
                  type="text"
                  placeholder="Refer code"
                  value={referCode}
                  onChange={(e) => setReferCode(e.target.value)}
                />
              </div> */}

              <div className="terms-cont">
                <input
                  type="checkbox"
                  id="term&condition"
                  checked={term}
                  onChange={(e) => setTerm(!term)}
                />
                <label htmlFor="term&condition">
                  By continuing, you agree our terms & condition
                </label>
              </div>

              <button className="btn-register" onClick={registerHandler}>
                Register
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PersonalDetails;
