import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar/NavBar";
import "./Home.scss";
import ImageCarousel from "../../components/ImageCarousel/ImageCarousel";
import TestCard from "../../components/TestCard/TestCard";
import { TbCheckupList } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchUser } from "../../slices/userSlice";
import { homeTests } from "../../slices/testList";
import InsideLoader from "../../components/InsideLoader/InsideLoader";
import axios from "axios";
import { drawerCategory } from "../../slices/drawerSlice";
import { toast } from "react-toastify";

const Home = () => {
  // const { user } = useSelector((state) => state.user);
  const { tests } = useSelector((state) => state.tests);
  const { user } = useSelector((state) => state.user);
  const { drawerValue } = useSelector((state) => state.customDrawer);
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCategory();
  }, []);

  useEffect(() => {
    fetchTest();
  }, [drawerValue, page]);

  const fetchTest = async () => {
    setLoading(true);
      await dispatch(
        homeTests({
          drawerValue: drawerValue &&  drawerValue._id,
          page: page,
          auth_token: user &&  user?.auth_token,
        })
      );
    

    setLoading(false);
  };

  const fetchCategory = async () => {
    setLoading(true);
    await dispatch(drawerCategory());
    setLoading(false);
  };
  return (
    <div className="homepage">
      <NavBar />
      <ImageCarousel />
      <div className="online-exams">
        <TbCheckupList />
        <p>Online Exams</p>
      </div>
      {loading ? (
        <InsideLoader />
      ) : (
        <div className="test-list">
          {tests?.tests?.map((item, index) => (
            <TestCard key={index} item={item} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Home;
