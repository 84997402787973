import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { useSelector } from "react-redux";
import { api, api2 } from "..";

const initialState = {
};

//fecth images from apis
export const homeTests = createAsyncThunk(
  "tests/homeTests",
  async ({ drawerValue, page, auth_token }) => {
    let auth_toke = localStorage.getItem("auth_token");
    const headers = {
      Authorization: `Bearer ${auth_token || auth_toke}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    try {
      const { data } = await api2.get(
        `/fetch/test/category/${drawerValue}?page=${page || 1}`, {headers}
      );

      if (data && data.success) {
        return data;
      }
    } catch (error) {
      console.log(error);
      return error;
    }
  }
);

export const testSlice = createSlice({
  name: "tests",
  initialState,
  reducers: {
    setvalue: (state, action) => {
      state.tests = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder

      //fetch tests
      .addCase(homeTests.pending, (state) => {
        state.status = "loading";
      })
      .addCase(homeTests.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.tests = action.payload;
      })
      .addCase(homeTests.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default testSlice.reducer;
