import React from "react";
import "./NavBar.scss";
import { TbCoinRupee } from "react-icons/tb";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaHistory } from "react-icons/fa";

const PageNavBar = ({ name, date, rightIcon, toggleDrawer, backNav }) => {
  const navigate = useNavigate();

  const backBtnHandler = () => {
    if (backNav) {
      backNav();
      return;
    }

    navigate("/");
  };
  return (
    <div className="test-nav">
      <div>
        <span onClick={() => backBtnHandler()}>
          <IoMdArrowBack />
        </span>
        <div>
          <p>{name}</p>
          {date && <p>Test Date: {date}</p>}
        </div>
      </div>
      {rightIcon && (
        <span>
          {rightIcon === "TbCoinRupee" && <TbCoinRupee />}
          {rightIcon === "GiHamburgerMenu" && (
            <GiHamburgerMenu onClick={toggleDrawer} />
          )}
          {rightIcon === "History" && (
            <FaHistory onClick={() => navigate("/wallet/history")} />
          )}
        </span>
      )}
    </div>
  );
};

export default PageNavBar;
