import React, { useState, useEffect } from "react";
import AppLogo from "../../assets/app_logo.jpg";
import "./UserProfile.scss";
import PageNavBar from "../../components/NavBar/PageNavBar";
import { useDispatch, useSelector } from "react-redux";
import { api, api2 } from "../..";
import Loader from "../../components/Loader/Loader";
import { toast } from "react-toastify";
import { fetchUser, userSlice } from "../../slices/userSlice";

const UserProfile = () => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch()
  const [inputType, setInputType] = useState("text");
  const [name, setName] = useState(user?.name);
  const [email, setEmail] = useState(user?.email);
  const [collegeName, setCollegeName] = useState(user?.school_name);
  const [dateOfBirth, setDateOfBirth] = useState(user?.date_of_birth);
  const [gender, setGender] = useState(user?.gender);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({
    id: user && user?.country?._id,
    country: user && user?.country?.country,
  });
  const [selectedState, setSelectedState] = useState({
    id: user && user?.state?._id,
    state: user && user?.state?.state,
  });
  const [selectedCity, setSelectedCity] = useState({
    id: user && user?.city?._id,
    city: user && user?.city?.city,
  });
  useEffect(() => {
    // Fetch all countries when the component loads
    fetchCountriesData();
  }, []);

  const fetchCountriesData = async () => {
    const { data } = await api2.get(`/fetch/country`);

    if (data) {
      setCountries(data && data?.country);
    }
  };

  //country handler
  const handleCountryChange = async (event) => {
    const selectedCountryData = JSON.parse(event.target.value);
    setSelectedCountry(selectedCountryData);

    try {
      const { data } = await api2.get(`/fetch/state/${selectedCountryData.id}`);

      if (data) {
        setStates(data?.state);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleStateChange = async (event) => {
    const selectedStateData = JSON.parse(event.target.value);
    setSelectedState(selectedStateData);

    try {
      const { data } = await api2.get(`/fetch/city/${selectedStateData.id}`);
      if (data) {
        setCities(data?.city);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCityChange = async (event) => {
    const selectedCityData = JSON.parse(event.target.value);
    setSelectedCity(selectedCityData);
  };

  const saveHandler = async () => {
    try {
      setLoading(true);
      let auth_token = localStorage.getItem('auth_token');
      if (!auth_token) {
        console.log("no token");
        return;
      }
      const headers = {
        Authorization: `Bearer ${auth_token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      };

      const { data } = await api2.post(`/user/update/profile`, {
        name: name,
        email: email,
        school_name: collegeName,
        date_of_birth: dateOfBirth,
        gender: gender,
        country: selectedCountry.id,
        state: selectedState.id,
        city: selectedCity.id,
      }, {headers});

      if(data && data.success) {
        toast.success("Profile updated successfully");
        let auth_token = localStorage.getItem('auth_token');
        dispatch(fetchUser({auth_token}))
      }

      

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message || error.message);
    }
  };
  return (
    <>
      <PageNavBar name="Profile Details" />
      {loading && <Loader />}
      <div className="container">
        <div className="logincard">
          <div className="applogo">
            <img src={AppLogo} alt="AppLogo" />
          </div>
          <div className="basic-info">
            <p className="title">Basic Info</p>
            <div className="details">
              <div className="input-cont">
                <label>Name:</label>
                <input
                  type="text"
                  placeholder="Please enter your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="input-cont">
                <label>Email:</label>
                <input
                  type="email"
                  placeholder="Please enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="input-cont">
                <label>College Name:</label>
                <input
                  type="text"
                  placeholder="Please enter your school/college name"
                  value={collegeName}
                  onChange={(e) => setCollegeName(e.target.value)}
                />
              </div>

              <div className="input-cont">
                <label>Date Of Birth: </label>
                <input
                  type={inputType}
                  placeholder="dd-mm-yy"
                  onFocus={() => setInputType("date")}
                  onBlur={() => setInputType("text")}
                  value={dateOfBirth}
                  onChange={(e) => setDateOfBirth(e.target.value)}
                />
              </div>

              <div className="gender-cont">
                <p>Please select your gender</p>
                <input
                  type="radio"
                  name="gender"
                  id="male"
                  checked={gender === "Male"}
                  onChange={() => setGender("Male")}
                />
                <label htmlFor="male">Male</label>
                <input
                  type="radio"
                  name="gender"
                  id="female"
                  checked={gender === "Female"}
                  onChange={() => setGender("Female")}
                />
                <label htmlFor="female">female</label>
              </div>
            </div>
          </div>

          <div className="basic-info">
            <div className="details">
              <div className="input-cont">
                <label>State: ( {selectedCountry?.country})</label>{" "}
                <select
                  value={JSON.stringify(selectedCountry.country)}
                  onChange={(e) => handleCountryChange(e)}
                >
                  <option value="">Select State</option>
                  {countries &&
                    countries.map((country, index) => (
                      <option
                        key={index}
                        value={JSON.stringify({
                          id: country._id,
                          country_name: country.country,
                        })}
                      >
                        {country.country}
                      </option>
                    ))}
                </select>
              </div>

              <div className="input-cont">
                <label>District: ({selectedState?.state} )</label>
                <select
                  value={selectedState?.state}
                  onChange={(e) => handleStateChange(e)}
                >
                  <option value="">Select District</option>
                  {states &&
                    states.map((state, index) => (
                      <option
                        key={index}
                        value={JSON.stringify({
                          id: state._id,
                          country_name: state.state,
                        })}
                      >
                        {state.state}
                      </option>
                    ))}
                </select>
              </div>

              <div className="input-cont">
                <label>City: ( {selectedCity?.city} ) </label>
                <select
                  value={JSON.stringify(selectedCity.city)}
                  onChange={(e) => handleCityChange(e)}
                >
                  <option value="">Select City</option>
                  {cities &&
                    cities.map((city, index) => (
                      <option
                        key={index}
                        value={JSON.stringify({
                          id: city._id,
                          country_name: city.city,
                        })}
                      >
                        {city.city}
                      </option>
                    ))}
                </select>
              </div>

              <button className="btn-register" onClick={saveHandler}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
