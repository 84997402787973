import React, { useState } from "react";
import "./TestDetail.scss";
import { FaMedal } from "react-icons/fa";
import { BsCashStack } from "react-icons/bs";
import { FaQuestionCircle } from "react-icons/fa";
import { FaCircleCheck } from "react-icons/fa6";
import AppLogo from "../../assets/app_logo.jpg";
import PageNavBar from "../../components/NavBar/PageNavBar";
import AlertLogo from "../../assets/alert.json";
import Lottie from "lottie-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "react-toastify";
import TimerComponet from "../../components/TimerComponet/TimerComponet";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { setUser, userSlice } from "../../slices/userSlice";
import { api, api2 } from "../..";
import { questionSlice, testQuestionFetch } from "../../slices/testQuestions";
import { RxCross2 } from "react-icons/rx";
import Loader from "../../components/Loader/Loader";

const TestDetail = () => {
  const { user } = useSelector((state) => state.user);
  const { drawerValue } = useSelector((state) => state?.customDrawer);
  const testQuestions = useSelector((state) => state?.testQuestions);
  const [paymentCont, setPaymentCont] = useState(false);
  const [paymentFreeCont, setPaymentFreeCont] = useState(false);
  const [paymentMode, setPaymentMode] = useState("");
  const [tabs, setTabs] = useState("cashPrize");
  const [currTime, setCurrTime] = useState("");
  const [testIsRun, setTestIsRun] = useState(false);
  const [ruleAlert, setRuleAlert] = useState(false);
  const [subjectAlert, setSubjectAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [testData, setTestData] = useState();
  const transcationIdRandom = uuidv4().slice(0, 6);
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const { item } = location.state || {};

  const navigate = useNavigate();
  const [selectSubject, setSelectSubject] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [subjectBtn, setSubjectBtn] = useState();
  const [testBtnDecide, setTestBtnDecide] = useState("");
  const [resultData, setResultData] = useState([]);

  const switcherFun = (data) => {
    setTabs(data);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const freeTestHandler = async () => {
    try {
      setLoading(true);
      const { data } = await api2.post(`/student/enroll`, {
        testId: testData?._id,
        userId: user?._id,
      });

      if (data && data.success) {
        toast.success("Test Enrolled Successfully");
      }
      setPaymentFreeCont(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message || error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!paymentFreeCont) {
      fetchTestData();
    }
  }, [paymentFreeCont]);

  const fetchTestData = async () => {
    try {
      setLoading(true);
      const { data } = await api2.get(`/fetch/test/${id}`);

      if (data && data.success) {
        setCurrTime(data && data?.timestamp);
        setTestData(data && data?.test);
        setSubjectBtn(data && data?.test?.subject);
      }

      setLoading(false);
    } catch (error) {
      toast.error(error.response.data.message || error.message);
      setLoading(false);
    }
  };

  const startTestHandler = async () => {
    setSubjectAlert(true);
  };

  const joinedHandler = () => {
    if (parseInt(testData.joinAmount) > 0) {
      setPaymentCont(true);
    } else {
      setPaymentFreeCont(true);
    }
  };

  const cancelPaymentHandler = () => {
    setPaymentCont(false);
  };

  //join test handler
  const paymentHandler = async () => {
    if (paymentMode === "") {
      toast.warning("Please select payment mode");
      return;
    }

    if (paymentMode === "online") {
      const options = {
        key: "rzp_live_1k9vbBh0HX0Ie7",
        amount: parseInt(item?.join_amount) * 100,
        currency: "INR",
        name: "B comptetion",
        // order_id: `online_${transcationIdRandom}`,
        description: item?.test_title,
        handler: async (pay_response) => {
          console.log(pay_response?.razorpay_payment_id);

          if (pay_response) {
            const formData = new FormData();
            formData.append("auth_token", user?.data[0]?.auth_token);
            formData.append("auth_id", user?.data[0]?.auth_id);
            formData.append("test_id", item?.test_id);
            formData.append(
              "transaction_number",
              pay_response?.razorpay_payment_id || transcationIdRandom
            );
            formData.append("join_amount", item?.join_amount);
            formData.append("payment_mode", "Online");

            const response = await api.post(
              "/test_api/Test/student_test_subscription",
              formData
            );

            console.log(response);
            if (response?.data) {
              toast.success("Payment successful");
              navigate("/");
            }
          }
        },
        theme: {
          color: "#F37254",
        },
        config: {
          display: {
            blocks: {
              upi: {
                name: "PhonePe / UPI",
                instruments: [
                  {
                    method: "upi",
                    flows: ["collect", "intent"],
                  },
                ],
              },
            },
            sequence: ["block.upi"], // Prioritize UPI/PhonePe
            preferences: {
              show_default_blocks: true, // Show other default options like cards, net banking
            },
          },
        },
        modal: {
          ondismiss: () => {
            console.log("Payment was not completed");
            toast.error("Payment was not successful or was canceled");
          },
        },
      };

      try {
        const razorpay = new window.Razorpay(options);
        console.log(razorpay);
        razorpay.open();
      } catch (error) {
        console.log("Payment initiation failed", error);
        toast.error("Unable to initiate payment. Please try again.");
      }
    }

    if (paymentMode === "wallet") {
      if (
        parseInt(user?.data[0]?.wallet_amount) < parseInt(item?.join_amount)
      ) {
        toast.warning("Insufficient balance in wallet.");
        return;
      }

      const formData = new FormData();
      formData.append("auth_token", user?.data[0]?.auth_token);
      formData.append("auth_id", user?.data[0]?.auth_id);
      formData.append("test_id", item?.test_id);
      formData.append("transaction_number", transcationIdRandom);
      formData.append("join_amount", item?.join_amount);
      formData.append("payment_mode", "Wallet");

      const response = await api.post(
        "/test_api/Test/student_test_subscription",
        formData
      );
      if (response?.data?.status === 0) {
        toast.warning("Something went wrong please login again");
        return;
      }
      if (response?.data?.status === 1) {
        if (user?.data && user.data[0] && item?.join_amount) {
          const newWalletAmount =
            parseInt(user?.data[0]?.wallet_amount) -
            parseInt(item?.join_amount);
          const newData = user.data.map((entry, index) =>
            index === 0 ? { ...entry, wallet_amount: newWalletAmount } : entry
          );

          dispatch(setUser({ ...user, data: newData }));

          toast.success("Payment successful");
          navigate("/");
        }
      }
    }
  };

  const addSuject = (data) => {
    let cunt = -1;

    if (selectSubject.length >= parseInt(testData?.totalAttemptSubject)) {
      toast.error(
        `You can select maximum ${testData?.totalAttemptSubject} subjects`
      );
      return;
    }
    if (selectSubject.length > 0) {
      cunt = selectSubject?.indexOf(data);
    }

    if (cunt > -1) {
      toast.error("subject is already selected");
      return;
    }

    setSelectSubject([...selectSubject, data]);
    selectedQuestions.push({ subject: data.subject, question: data.question });
  };

  const removeSubject = (index) => {
    setSelectSubject(selectSubject.filter((_, i) => i !== index));
  };

  const testStartHandler = () => {
    if (selectSubject.length !== parseInt(testData?.totalAttemptSubject)) {
      toast.error(`Please select ${testData?.totalAttemptSubject} subject`);
      return;
    }
    dispatch(
      questionSlice.actions.setSelectedSubjects({ subjects: selectSubject })
    );
    dispatch(
      questionSlice.actions.setSelectedQuesBySubject({
        questions: selectedQuestions,
      })
    );
    navigate(`/test/${id}`, { state: { item: testData, timestamp: currTime } });
  };

  useEffect(() => {
    setBtnTest();
    fetchLeaderboard();
  }, [testData]);

  const exactDate = new Date(`${testData && testData?.publish_at}`);
  const overDate = new Date(`${testData && testData?.unpublish_at}`);
  const setBtnTest = () => {
    setLoading(true);
    const exitStudent = testData?.enrolledStudents?.findIndex(
      (student) => student._id === user?._id
    );

    // console.log(testData && testData?._id, user?.submittedTests);
    let index = user?.submittedTests?.findIndex(
      (test) => test === testData?._id
    );

    const currentTime = new Date(currTime);
    setTestBtnDecide("next_time");

    //attempted
    if (index !== -1) {
      setTestBtnDecide("attempted");
      setLoading(false);
      return;
    }

    //not joined
    if (
      exitStudent === -1 &&
      parseInt(testData?.slots) > 0 &&
      currentTime < exactDate
    ) {
      setTestBtnDecide("notJoined");
      setLoading(false);

      return;
    }

    if (
      exitStudent !== -1 &&
      currentTime >= exactDate &&
      currentTime <= overDate
    ) {
      setTestBtnDecide("start");
      setLoading(false);

      return;
    }

    if (currentTime > overDate) {
      setTestBtnDecide("over");
      setLoading(false);

      return;
    }

    if (exitStudent !== -1) {
      setTestBtnDecide("none");
      setLoading(false);
      return;
    }
    setLoading(false);
  };

  const fetchLeaderboard = async () => {
    try {
      setLoading(true);
      const { data } = await api2.get(`/fetch/test/result/${id}`);

      if (data && data?.success) {
        setResultData(data && data?.test?.submittedStudents);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message || error.message);
      setLoading(false);
    }
  };

  return (
    <div className="main-parent-test-detail">
      {testQuestions?.status === "loading" && <Loader />}
      {loading && <Loader />}
      <PageNavBar
        name={testData?.testTitle}
        date={testData?.publish_at?.split("T")[0]}
      />

      {paymentFreeCont && (
        <div className="payment-mode">
          <div>
            <div className="heading">
              <p>Free Test</p>
            </div>
            <div className="select-container">Are you want join test?</div>

            <div className="btn">
              <button onClick={() => setPaymentFreeCont(false)}>cancel</button>
              <button onClick={freeTestHandler}>Join</button>
            </div>
          </div>
        </div>
      )}
      {paymentCont && (
        <div className="payment-mode">
          <div>
            <div className="heading">
              <p>Choose a payment mode</p>
            </div>
            <div className="select-container">
              <div className="select">
                <input
                  type="radio"
                  id="wallet"
                  name="payment-mode"
                  onClick={() => setPaymentMode("wallet")}
                />
                <label htmlFor="wallet" name="payment-mode">
                  Wallet
                </label>
              </div>
              <div className="select">
                <input
                  type="radio"
                  id="online"
                  name="payment-mode"
                  onClick={() => setPaymentMode("online")}
                />
                <label htmlFor="online" name="payment-mode">
                  Online
                </label>
              </div>
            </div>

            <div className="btn">
              <button onClick={cancelPaymentHandler}>cancel</button>
              <button onClick={paymentHandler}>pay</button>
            </div>
          </div>
        </div>
      )}

      <div className="test-detail">
        {ruleAlert && (
          <div className="rule-container" onClick={() => setRuleAlert(false)}>
            <div onClick={() => setRuleAlert(true)}>
              <ul className="bold-txt">
                <li>Follow the rules</li>
                <li>No cheating</li>
                <li>Answer all questions</li>
              </ul>

              <Lottie animationData={AlertLogo} loop={true} />

              <ul className="normal-txt">
                <li>Follow the rules</li>
                <li>No cheating</li>
                <li>Answer all questions</li>
              </ul>

              <button onClick={startTestHandler}>Start Test</button>
            </div>
          </div>
        )}

        {subjectAlert && (
          <div className="select-sub-cont">
            <div>
              <div className="heading">
                <p>Select Subject</p>
              </div>
              <p
                style={{
                  textAlign: "center",
                  color: "var(--primary-color)",
                  marginTop: "10px",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                Please select carefully {subjectBtn?.length - 1} subjects
              </p>

              <div className="subject-btn-cont">
                {subjectBtn &&
                  subjectBtn?.map((item, index) => (
                    <button onClick={() => addSuject(item)} key={index}>
                      {item?.subject}
                    </button>
                  ))}
              </div>
              <div className="selected-subject-cont">
                {selectSubject?.map((item, index) => (
                  <div className="selected-subject" key={index}>
                    {item?.subject}
                    <RxCross2 onClick={() => removeSubject(index)} />
                  </div>
                ))}
              </div>

              <div className="btn">
                <button onClick={() => setSubjectAlert(false)}>cancel</button>
                <button onClick={testStartHandler}>Save & Start</button>
              </div>
            </div>
          </div>
        )}

        <div className="prize-pool">
          <div>
            <FaMedal />
            <div>
              <p className="prize-txt">Prize Pool</p>
              <p className="price">₹ {parseInt(item?.prizePool)}</p>
            </div>
          </div>
          <div>
            {/* new method */}

            <div>
              {/* already attempted */}
              {testBtnDecide === "attempted" && (
                <p style={{ color: "red", fontWeight: "600" }}>
                  Already Attempted
                </p>
              )}

              {/* not joined */}
              {testBtnDecide === "notJoined" && (
                <p className="start-test" onClick={() => joinedHandler()}>
                  Join Test
                </p>
              )}

              {/* start test */}
              {testBtnDecide === "start" && (
                <p className="start-test" onClick={() => setRuleAlert(true)}>
                  Start Test
                </p>
              )}

              {/* better luck next time */}
              {testBtnDecide === "next_time" && (
                <p style={{ color: "red", fontWeight: "600" }}>
                  Better Luck Next Time
                </p>
              )}
              {/* better luck next time */}
              {testBtnDecide === "over" && (
                <p style={{ color: "red", fontWeight: "600" }}>Test is Over</p>
              )}

              {/* timer components */}
              {new Date() < exactDate && (
                <TimerComponet
                  item={testData}
                  currTime={currTime}
                  testIsRun={testIsRun}
                  setTestIsRun={setTestIsRun}
                />
              )}
            </div>
          </div>
        </div>
        <div className="test-detail-cont">
          <div>
            <p className="data-txt">Total Joined Students</p>
            <p className="txt-red">{testData?.enrolledStudents?.length}</p>
          </div>
          <div>
            <div>
              <p>Entry Fee</p>
              <div className="cont">
                <BsCashStack /> <span>₹ {testData?.joinAmount}</span>
              </div>
            </div>
            <div>
              <p>Total Questions</p>
              <div className="cont">
                <FaQuestionCircle />
                <span>{testData?.totalAttemptQuestion}</span>
              </div>
            </div>
            <div>
              <p>Total Marks</p>
              <div className="cont">
                <FaCircleCheck />
                <span>{testData?.totalAttemptQuestion} Marks</span>
              </div>
            </div>
          </div>
          <div>
            <p className="data-txt">Exam Timing</p>
            <p className="txt-red">
              {testData?.publish_at?.split("T")[1]} -{" "}
              {testData?.unpublish_at?.split("T")[1]}
            </p>
          </div>
        </div>

        <div className="tabs-switch-cont">
          <div onClick={() => switcherFun("cashPrize")}>
            <p className={tabs === "cashPrize" ? "active" : ""}>Reward</p>
            <div className={`bar ${tabs === "cashPrize" ? "baractive" : ""}`} />
          </div>
          <hr />
          <div onClick={() => switcherFun("leaderboard")}>
            <p className={tabs === "leaderboard" ? "active" : ""}>
              Leaderboard
            </p>
            <div
              className={`bar ${tabs === "leaderboard" ? "baractive" : ""}`}
            />
          </div>
          <hr />
          <div onClick={() => switcherFun("examDetails")}>
            <p className={tabs === "examDetails" ? "active" : ""}>
              Exam Details
            </p>
            <div
              className={`bar ${tabs === "examDetails" ? "baractive" : ""}`}
            />
          </div>
          <hr />
          <div onClick={() => switcherFun("pointSystem")}>
            <p className={tabs === "pointSystem" ? "active" : ""}>
              Point System
            </p>
            <div
              className={`bar ${tabs === "pointSystem" ? "baractive" : ""}`}
            />
          </div>
        </div>
        <div className="scroll-cont">
          {tabs === "cashPrize" ? (
            <div className="tabs-detail">
              <div className="rank">
                <p>RANK</p>
                <p>Prize</p>
              </div>
              {testData?.cash_prize?.map((item, index) => (
                <div key={index}>
                  <p>{item?.rank}</p>
                  <p>₹ {item?.amount}</p>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
          {tabs === "leaderboard" ? (
            <div className="tabs-detail">
              <div className="rank">
                <p>RANK</p>
                <p>Name</p>
                <p>Marks/Time</p>
              </div>
              {!testData?.result_publish &&
                testData?.enrolledStudents &&
                (() => {
                  // Sort and rank the leaderboard
                  const sortedLeaderboard = testData.enrolledStudents.map(
                    (student, index) => ({
                      ...student,
                      rank: index + 1,
                    })
                  );

                  // Find the logged-in user's index
                  const userIndex = sortedLeaderboard.findIndex(
                    (student) => student._id === user?._id
                  );

                  let updatedLeaderboard = [...sortedLeaderboard];

                  // If the logged-in user is found, prepend them to the leaderboard
                  if (userIndex !== -1) {
                    const [loggedInUser] = updatedLeaderboard.splice(
                      userIndex,
                      1
                    );
                    updatedLeaderboard = [loggedInUser, ...updatedLeaderboard];
                  }

                  // Render the leaderboard
                  return updatedLeaderboard.map((student, index) => (
                    <div key={student._id}>
                      <p>{student.rank}</p>
                      <p>{student.name}</p>
                      <p>0 / 00:00</p>
                    </div>
                  ));
                })()}
              {testData?.result_publish &&
                (() => {
                  // Preserve original rank while moving the logged-in user to the top
                  const sortedResultData = resultData.map((item, index) => ({
                    ...item,
                    rank: index + 1,
                  }));

                  const userIndex = sortedResultData.findIndex(
                    (item) => item?.user?._id === user?._id
                  );

                  let updatedResultData = [...sortedResultData];

                  // If the logged-in user is found, prepend them while keeping their original rank
                  if (userIndex !== -1) {
                    const [loggedInUser] = updatedResultData.splice(
                      userIndex,
                      1
                    );
                    updatedResultData = [loggedInUser, ...updatedResultData];
                  }

                  // Render the leaderboard
                  return updatedResultData.map((item) => (
                    <div key={item._id}>
                      <p>{item.rank}</p>
                      <p>{item?.user?.name}</p>
                      <p>
                        {item?.marks} / {item?.completedTime}
                      </p>
                    </div>
                  ));
                })()}
            </div>
          ) : (
            ""
          )}

          {tabs === "pointSystem" ? (
            <div className="pointSystem-detail">
              <img src={AppLogo} alt="AppLogo" />
              <p>Total Marks : {item?.totalAttemptQuestion}</p>
              <p>{item?.pointSystem}</p>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default TestDetail;
