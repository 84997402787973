import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { api, api2 } from "..";

const initialState = {
  drawer: [],
  drawerValue: {},
};

//fecth category from apis
export const drawerCategory = createAsyncThunk(
  "drawer/drawerCategory",
  async () => {
    try {
      const { data } = await api2.get("/fetch/category");
      if (data && data.success) {
        return data;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  }
);

export const drawerSlice = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    setvalue: (state, action) => {
      state.drawerValue = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder

      //fetch drawer
      .addCase(drawerCategory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(drawerCategory.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.drawer = action.payload;
        state.drawerValue = action.payload?.categories[0];
      })
      .addCase(drawerCategory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default drawerSlice.reducer;
