import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { Provider } from "react-redux";
import store from "./store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const baseUrl = true ? "https://bonlinemarket.com" : "http://localhost:3000";

const baseUrl2 = "https://backend-node.bonlinemarket.com/api/v1/user";

export const api = axios.create({
  baseURL: baseUrl,
});
export const api2 = axios.create({
  baseURL: baseUrl2,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
    <ToastContainer autoClose={3000} draggable={true} />
  </Provider>
);
