import React from "react";
import "./NavBar.scss";
import { useSelector } from "react-redux";

const RightSideBar = ({
  isOpen,
  toggleDrawer,
  directQuesNav,
  setSubmitDialog,
  question,
}) => {
  const testQuestions = useSelector((state) => state.testQuestions);
  const attemptedQuestions = testQuestions?.attemptedQuestion || [];
  const attemptedCount = attemptedQuestions.length;

  return (
    <div>
      <div className={`drawer ${isOpen ? "open" : ""}`}>
        <p className="data">
          <span>Total Questions: </span> {question?.length || 0}
        </p>
        <p className="data">
          <span>Total Attempted: </span> {attemptedCount}
        </p>
        <div className="question-symbol">
          <div className="symbol-cont">
            <div className="green"></div>
            <span>Attempted</span>
          </div>
          <div className="symbol-cont">
            <div className="yellow"></div>
            <span>Marked</span>
          </div>
          <div className="symbol-cont">
            <div className="normal"></div>
            <span>Un-attempted</span>
          </div>
        </div>
        <div className="questions-cont">
          {question?.map((item, index) => {
            const attempt = attemptedQuestions.find(
              (attempted) => attempted.quesId === item?._id
            );

            return (
              <div
                onClick={() => directQuesNav(index)}
                key={item._id || index}
                className={`${attempt?.status === "save" ? "green" : ""} ${
                  attempt?.status === "marked" ? "yellow" : ""
                }`}
              >
                {index + 1}
              </div>
            );
          })}
        </div>

        <div className="btn">
          <button
            onClick={() => {
              setSubmitDialog(true);
              toggleDrawer();
            }}
          >
            Submit
          </button>
        </div>
      </div>
      {isOpen && <div className="backdrop" onClick={toggleDrawer}></div>}
    </div>
  );
};

export default RightSideBar;
