import React from "react";
import Avatar from "../../assets/avatar.png";
import { FaRegEdit } from "react-icons/fa";
import { CiUser } from "react-icons/ci";
import { TbCheckupList } from "react-icons/tb";
import { TfiCup } from "react-icons/tfi";
import { IoShareSocialOutline } from "react-icons/io5";
import { MdSupportAgent } from "react-icons/md";
import { HiLanguage } from "react-icons/hi2";
import { RiUserShared2Line } from "react-icons/ri";
import { IoIosPower } from "react-icons/io";
import facebook from "../../assets/facebook.png";
import youtube from "../../assets/youtube.png";
import whatsapp from "../../assets/whatsapp.png";
import insta from "../../assets/instagram.png";
import twitter from "../../assets/twitter.png";
import telegram from "../../assets/telegram.png";
import { IoWalletOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../slices/userSlice";
import { MdOutlineContactSupport } from "react-icons/md";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { RiSecurePaymentFill } from "react-icons/ri";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { BsCardChecklist } from "react-icons/bs";

const SideBar = ({ isOpen, toggleSidebar }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.user);

  //share hander
  const shareHandler = () => {
    const message = encodeURIComponent(
      `📌Don't Miss Out! Simply click on this link to grab this offer:\n https://play.google.com/store/apps/details?id=com.jbccNew \n \n https://bcompetition.com \n ${user?.name} 💰Use The Referral Code: ${user?.referral_code} \n Let's read, Learn, Earn, win, and Succeed Together!💪🏻📚🤑🏆`
    );
    const shareUrl = `https://wa.me/?text=${message}`;
    window.open(shareUrl, "_blank");
  };

  //logout handler
  const logoutHandler = async () => {
    const response = await dispatch(logoutUser());
    localStorage.removeItem("auth_token");
    navigate("/login");
  };
  return (
    <div
      className={`sidebarcont ${isOpen ? "active" : ""}`}
      onClick={toggleSidebar}
    >
      <div className={`sidebar ${isOpen ? "active" : ""}`}>
        <div className="profile">
          <div>
            <img src={Avatar} alt="Avatar" />
            <div>
              {<p className="name">{user?.name}</p>}
              {<p className="phone">{user?.mobile_number}</p>}
            </div>
          </div>
          <FaRegEdit />
        </div>
        <hr style={{ marginTop: "10px" }} />
        <div className="options">
          <div className="op-tab" onClick={() => navigate("/profile-detail")}>
            <CiUser />
            <p>Profile</p>
          </div>
          <div className="op-tab" onClick={() => navigate("/my-exams")}>
            <TbCheckupList />
            <p>My Exam</p>
          </div>
          <div className="op-tab" onClick={() => navigate("/result")}>
            <TfiCup />
            <p>Result</p>
          </div>
          <div className="op-tab" onClick={() => navigate("/wallet")}>
            <IoWalletOutline />
            <p>Wallet</p>
          </div>

          <div className="op-tab" onClick={shareHandler}>
            <IoShareSocialOutline />
            <p>Share</p>
          </div>
          <div className="op-tab" onClick={() => navigate("/help-center")}>
            <MdSupportAgent />
            <p>Help Center</p>
          </div>
          <div className="op-tab" onClick={() => navigate("/language")}>
            <HiLanguage />
            <p>Language</p>
          </div>
          <div className="op-tab" onClick={() => navigate("/refer-earn")}>
            <RiUserShared2Line />
            <p>Refer & Earn</p>
          </div>
          <div
            className="op-tab"
            onClick={() => navigate("/term-and-condition")}
          >
            <BsCardChecklist />
            <p>Term & Condition</p>
          </div>
          <div
            className="op-tab"
            onClick={() => navigate("/privacy-and-policy")}
          >
            <MdOutlinePrivacyTip />
            <p>Privacy & Policiy</p>
          </div>
          <div
            className="op-tab"
            onClick={() => navigate("/payment-and-policy")}
          >
            <RiSecurePaymentFill />
            <p>Payment & Poilcy</p>
          </div>
          <div className="op-tab">
            <IoIosPower />
            <p onClick={logoutHandler}>Logout</p>
          </div>
          <div className="social-icon">
            <img src={facebook} alt="facebook" />
            <img src={youtube} alt="youtube" />
            <img src={whatsapp} alt="whatsapp" />
            <img src={insta} alt="insta" />
            <img src={twitter} alt="twitter" />
            <img src={telegram} alt="telegram" />
          </div>
        </div>
      </div>
      {isOpen && <div className="overlay" onClick={toggleSidebar}></div>}
    </div>
  );
};

export default SideBar;
